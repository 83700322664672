/**
 * @generated SignedSource<<b0c3dcae66186230050ce3bc30ab97c7>>
 * @relayHash 508b8e22f3040717632ce33d5976d231
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/492.0.0-2024-12-12T19:54:28.594Z/ReturnPolicyTranslationProviderQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReturnPolicyTranslationProviderQuery$variables = {
  hasItemIds?: boolean | null;
  hasTransactionId?: boolean | null;
  itemIds: ReadonlyArray<string | null>;
  transactionId: string;
  userId: string;
};
export type ReturnPolicyTranslationProviderQuery$data = {
  readonly viewer: {
    readonly itemRead?: ReadonlyArray<{
      readonly returnPolicy?: {
        readonly additionalNotes: string | null;
      } | null;
    } | null> | null;
    readonly transaction?: {
      readonly returnPolicy: {
        readonly additionalNotes: string | null;
      } | null;
    } | null;
  };
};
export type ReturnPolicyTranslationProviderQuery = {
  response: ReturnPolicyTranslationProviderQuery$data;
  variables: ReturnPolicyTranslationProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "hasItemIds"
},
v1 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "hasTransactionId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "itemIds",
    "variableName": "itemIds"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalNotes",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = [
  {
    "kind": "Variable",
    "name": "transactionId",
    "variableName": "transactionId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v7/*: any*/),
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReturnPolicyTranslationProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasItemIds",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "itemRead",
                "plural": true,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v6/*: any*/),
                        "concreteType": "ReturnPolicy",
                        "kind": "LinkedField",
                        "name": "returnPolicy",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "Item",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "hasTransactionId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReturnPolicy",
                    "kind": "LinkedField",
                    "name": "returnPolicy",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReturnPolicyTranslationProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasItemIds",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "itemRead",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v6/*: any*/),
                        "concreteType": "ReturnPolicy",
                        "kind": "LinkedField",
                        "name": "returnPolicy",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "Item",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "hasTransactionId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReturnPolicy",
                    "kind": "LinkedField",
                    "name": "returnPolicy",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/492.0.0-2024-12-12T19:54:28.594Z/ReturnPolicyTranslationProviderQuery",
    "metadata": {},
    "name": "ReturnPolicyTranslationProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8326613f5bb3ebee7964f8327071d9ac";

export default node;
