/**
 * @generated SignedSource<<8f71f1aa06808ea7b2dd202f570f3e19>>
 * @relayHash c2e89e85be5c9279f711d797a160b595
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/492.0.0-2024-12-12T19:54:28.594Z/footerRootQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type footerRootQuery$variables = {
  hasUserId: boolean;
  userCountryCode?: string | null;
  userId?: string | null;
};
export type footerRootQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"Footer_viewer">;
  };
};
export type footerRootQuery = {
  response: footerRootQuery$data;
  variables: footerRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v2 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "footerRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Footer_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "footerRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "footerNftLink",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "NFT_LINK"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"NFT_LINK\")"
          },
          {
            "alias": "footerQuestionsAnswersLink",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "QUESTIONS_LINK"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"QUESTIONS_LINK\")"
          },
          {
            "alias": "reviewsLink",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "REVIEWS_LINK"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"REVIEWS_LINK\")"
          },
          {
            "alias": "sitemapLinks",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "SITEMAP"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"SITEMAP\")"
          },
          {
            "alias": "wpEditorialLink",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "WP_EDITORIAL_LINK"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL_LINK\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "gdprDisplayInfo",
            "kind": "LinkedField",
            "name": "gdprDisplayInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "countryCode",
                    "variableName": "userCountryCode"
                  }
                ],
                "kind": "ScalarField",
                "name": "isGdprApplicable",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "userId"
                  }
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceId",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Profile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddressBook",
                    "kind": "LinkedField",
                    "name": "addressBook",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "personalAddress",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayCountry",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currencies",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Geography",
            "kind": "LinkedField",
            "name": "geography",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GeographyObject",
                "kind": "LinkedField",
                "name": "countries",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "abbreviation",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "scandinavianCurrenciesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "scandinavianCurrencies"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"scandinavianCurrencies\")"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/492.0.0-2024-12-12T19:54:28.594Z/footerRootQuery",
    "metadata": {},
    "name": "footerRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0c2b2b4a9e4388853191f805dfdfc1a4";

export default node;
